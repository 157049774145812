import { useMutation } from '@apollo/client';
import { Button, Form, Input, Space, Typography } from 'antd';
import React, { useEffect } from 'react';
import {
  ASSET_CATEGORY,
  MODULE_TYPES,
  STATUS_TYPES,
  THEMES
} from '../../../../../common/constants';
import { SelectAsset } from '../../../../videos/components/FormInputs';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import {
  KeyInput,
  ModuleFields,
  Permissions,
  ShowFields,
  ThemePicker
} from './FormInputs';

const DEFAULT_LEAD_GEN_COMPLETION_TITLE = 'Thank you for Subscribing';
const initialValues = {
  title: '',
  description: '',
  status: STATUS_TYPES.PUBLISHED,
  permissions: [],
  settings: {
    title: true,
    description: true
  },
  config: {
    key: '',
    theme: THEMES.BLUE_TINT,
    completionTitle: DEFAULT_LEAD_GEN_COMPLETION_TITLE,
    completionDescription: '',
    asset: { id: '', url: '' },
    iconAsset: { id: '', url: '' }
  }
};

const SETTINGS = [
  {
    name: 'title',
    label: 'Title',
    allowedTypes: [MODULE_TYPES.LEAD_GEN, MODULE_TYPES.CONTACT_US]
  },

  {
    name: 'description',
    label: 'Description',
    allowedTypes: [MODULE_TYPES.LEAD_GEN, MODULE_TYPES.CONTACT_US]
  }
];

const MODULE_KEYS = {
  [MODULE_TYPES.LEAD_GEN]: 'leadGenModule',
  [MODULE_TYPES.CONTACT_US]: 'contactUsModule'
};

const CONFIG_TITLE = {
  [MODULE_TYPES.LEAD_GEN]: 'Lead Gen Configs',
  [MODULE_TYPES.CONTACT_US]: 'Contact Us Configs'
};

const LEAD_GEN_THEMES = [THEMES.BLUE_TINT, THEMES.WHITE];

const dataSelector = ({ id, url }) => ({
  id,
  url
});

const LeadGenForm = ({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess,
  onSettingsChange
}) => {
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;

  const settingsProps = Form.useWatch(['settings'], form);
  useEffect(() => {
    if (settingsProps) {
      onSettingsChange(settingsProps);
    }
  }, [settingsProps]);

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      form.setFieldsValue({
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },
        config: {
          key: defaultValues?.moduleData?.config?.key ?? '',
          theme: defaultValues?.moduleData?.config?.theme ?? THEMES.BLUE,
          completionTitle:
            defaultValues?.moduleData?.config?.completionTitle ??
            DEFAULT_LEAD_GEN_COMPLETION_TITLE,
          completionDescription:
            defaultValues?.moduleData?.config?.completionDescription ?? '',
          asset: {
            id: defaultValues?.moduleData?.config?.asset?.id ?? '',
            url: defaultValues?.moduleData?.config?.asset?.url ?? ''
          },
          iconAsset: {
            id: defaultValues?.moduleData?.config?.iconAsset?.id ?? '',
            url: defaultValues?.moduleData?.config?.iconAsset?.url ?? ''
          }
        }
      });
    }
  }, [form, moduleId, formType, defaultValues, form, initialValues]);

  const handleSubmit = ({ config, settings, permissions, ...rest }) => {
    const key = MODULE_KEYS[type];
    if (!key) return;
    const { key: keyField, asset, iconAsset, ...restConfig } = config ?? {};
    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,

        ...(!(type === MODULE_TYPES.CONTACT_US) && {
          config: {
            ...(!isEdit && {
              key: keyField
            }),
            assetId: asset?.id,
            iconAssetId: iconAsset?.id,
            ...restConfig
          }
        })
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <ModuleFields />
      <Space className="w-full" direction="vertical">
        <ShowFields settings={SETTINGS} type={type} />

        <Space className="w-full" direction="vertical">
          <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
          <div>
            <Form.Item label="Key" name={['config', 'key']}>
              <KeyInput
                disabled={isEdit}
                readOnly={isEdit}
                placeholder="Enter key"
              />
            </Form.Item>
            {MODULE_TYPES.LEAD_GEN === type && (
              <>
                <Form.Item label="Theme" name={['config', 'theme']}>
                  <ThemePicker themes={LEAD_GEN_THEMES} />
                </Form.Item>
                <Form.Item label="Icon" name={['config', 'iconAsset']}>
                  <SelectAsset
                    modalTitle="Select Icon"
                    categoryKey={ASSET_CATEGORY.ICON}
                    btnText="Icon"
                    dataSelector={dataSelector}
                  />
                </Form.Item>
                <Form.Item label="Image" name={['config', 'asset']}>
                  <SelectAsset
                    modalTitle="Select Image"
                    categoryKey={ASSET_CATEGORY.IMAGE}
                    btnText="Image"
                    dataSelector={dataSelector}
                  />
                </Form.Item>
              </>
            )}
          </div>
          {MODULE_TYPES.LEAD_GEN === type && (
            <>
              <Typography.Text>Lead Gen Completion configs</Typography.Text>
              <div>
                <Form.Item label="Title" name={['config', 'completionTitle']}>
                  <Input placeholder="Enter completion title" />
                </Form.Item>
                <Form.Item
                  label="Description"
                  name={['config', 'completionDescription']}
                >
                  <Input.TextArea placeholder="Enter completion description" />
                </Form.Item>
              </div>
            </>
          )}
        </Space>

        <Permissions />
        <div className="d-flex button-section">
          <Space>
            <Form.Item>
              <Button
                type="text"
                htmlType="submit"
                className="text-btn mr-8"
                size="middle"
                disabled={loading}
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                disabled={loading}
                onClick={onCancel}
                type="text"
                className="text-btn2"
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Space>
    </Form>
  );
};

export default LeadGenForm;

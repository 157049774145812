import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as urlSlug from 'url-slug';
import { MODULES, PAGE_TYPES, ROUTES } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import CreatePageModal, {
  useCreatePage
} from '../../../components/CreatePageModal';
import PageHeader from '../../../components/PageHeader';
import history from '../../../historyData';
import { Switch } from '../../pages/component/pageModules/moduleForms/FormInputs';
import { Editor } from '../../videos/components/FormInputs';
import { CREATE_AUTHOR, UPDATE_AUTHOR } from './graphql/Mutations';
import { GET_AUTHOR } from './graphql/Queries';

const { required } = formValidatorRules;

const initialValues = {
  isActive: true,
  firstName: '',
  lastName: '',
  slug: '',
  description: '',
  subTitle: '',
  image: null,
  thumbnail: null,
  metaHeader: '',
  metaFooter: ''
};

const AddEditAuthor = (props) => {
  const [form] = Form.useForm();
  const { location } = props;
  const { id: authorId } = useParams();
  const { isUpdate } = location?.state || {};
  const {
    page,
    TYPES,
    setPage,
    getModalProps,
    openModal,
    navigateToEditPage
  } = useCreatePage(PAGE_TYPES.AUTHOR);

  const [colorCode, setColorCode] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [fetchAuthor, { loading: fetchingDetails }] = useLazyQuery(GET_AUTHOR, {
    variables: { where: { id: authorId } },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      form.setFieldsValue({
        ...res?.authorAdmin,
        slug: res?.authorAdmin?.slug?.startsWith('/')
          ? res?.authorAdmin?.slug
          : `/${res?.authorAdmin?.slug}`
      });

      setColorCode(res?.authorAdmin?.primaryColor);

      setPage({
        ...res?.authorAdmin?.page,
        slug: res?.authorAdmin?.slug ?? ''
      });
    }
  });

  useEffect(() => {
    if (authorId) {
      fetchAuthor();
    }
  }, [authorId]);

  const [createAuthorMutate] = useMutation(CREATE_AUTHOR, {
    onError() {
      setButtonLoading(false);
    }
  });
  const [updateAuthorMutate] = useMutation(UPDATE_AUTHOR, {
    onError() {
      setButtonLoading(false);
    }
  });

  const handleCancel = () => {
    history?.replace(ROUTES?.AUTHOR);
  };

  const onFinish = async (values) => {
    setButtonLoading(true);
    try {
      const formData = {
        firstName: values?.firstName,
        lastName: ' ',
        description: values?.description,
        subTitle: values?.subTitle,
        slug: values?.slug?.startsWith('/')
          ? values?.slug?.substring(1)
          : values?.slug,
        imageId: values?.image?.id ? values?.image?.id : null,
        thumbnailId: values?.thumbnail?.id ? values?.thumbnail?.id : null,
        primaryColor: colorCode,
        ...(authorId && {
          isActive: !!values?.isActive
        }),
        metaHeader: values?.metaHeader || '',
        metaFooter: values?.metaFooter || ''
      };

      if (isUpdate) {
        const response = await updateAuthorMutate({
          variables: {
            where: { id: authorId },
            data: {
              ...formData
            }
          }
        });
        if (response?.data?.updateAuthor) {
          setButtonLoading(false);
          history?.replace(ROUTES?.AUTHOR);
        }
      } else {
        const response = await createAuthorMutate({
          variables: {
            data: {
              ...formData
            }
          }
        });
        if (response?.data?.createAuthor) {
          setButtonLoading(false);
          history?.replace(ROUTES?.AUTHOR);
        }
      }
    } catch (error) {
      setButtonLoading(false);
      return error;
    }
  };

  const handleFirstNameChange = (e) => {
    const value = e?.target?.value;
    form?.setFieldValue('slug', `/${urlSlug?.convert(value)}`);
  };

  return (
    <>
      <CreatePageModal {...getModalProps({ title: 'Create Speaker Page' })} />
      <PageHeader menu={MODULES?.LABELS} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            initialValues={initialValues}
            name="create-asset"
            layout="vertical"
            className="add-edit-form"
            onFinish={onFinish}
          >
            <Form.Item
              name="firstName"
              label="Title"
              required
              rules={[
                formValidatorRules?.required('Please enter title!'),
                formValidatorRules?.maxLength(200, 'Max 200 characters allowed')
              ]}
            >
              <Input
                placeholder="Enter title"
                disabled={buttonLoading}
                onChange={handleFirstNameChange}
              />
            </Form.Item>

            <Form.Item
              name="slug"
              label="Slug"
              rules={[{ required, message: 'Please enter page slug!' }]}
            >
              <Input placeholder="Enter Page Slug" disabled={buttonLoading} />
            </Form.Item>
            {authorId && (
              <Form.Item name="isActive" valuePropName="checked">
                <Switch label="Active" />
              </Form.Item>
            )}

            <Form.Item name="description" label="Description">
              <Editor
                modules={{
                  toolbar: [['bold', 'italic', 'underline']]
                }}
              />
            </Form.Item>

            <Form.Item name="metaHeader" label="Meta Header">
              <Input.TextArea rows={5} placeholder="Enter meta header" />
            </Form.Item>

            <Form.Item name="metaFooter" label="Meta Footer">
              <Input.TextArea rows={5} placeholder="Enter meta footer" />
            </Form.Item>

            <div className="d-flex button-section mb-8">
              <Space>
                <Button
                  loading={buttonLoading}
                  type="text"
                  htmlType="submit"
                  className="text-btn mr-8"
                  size="middle"
                >
                  Save
                </Button>

                <Button
                  type="text"
                  className="text-btn2"
                  disabled={buttonLoading}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
            {isUpdate && page.type === TYPES.CREATE && (
              <Button
                disabled={fetchingDetails}
                htmlType="button"
                type="text"
                className="text-btn"
                onClick={openModal}
              >
                Create Custom Page
              </Button>
            )}
            {isUpdate && page.type === TYPES.UPDATE && (
              <Button
                disabled={fetchingDetails}
                htmlType="button"
                type="text"
                className="text-btn"
                onClick={navigateToEditPage}
              >
                Update Custom Page
              </Button>
            )}
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditAuthor;

import { useMutation } from '@apollo/client';
import { Button, Form, Input, Space, Typography } from 'antd';
import React, { useEffect } from 'react';
import {
  MODULE_TYPES,
  STATUS_TYPES,
  THEMES
} from '../../../../../common/constants';
import { SelectVideos } from '../../../../labels/collections/components/FormInputs';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import { KeyInput, ModuleFields, Permissions, ShowFields } from './FormInputs';

const initialValues = {
  title: '',
  description: '',
  status: STATUS_TYPES.PUBLISHED,
  permissions: [],
  settings: {
    description: true,
    secondaryTitleAndDescription: true
  },
  config: {
    key: '',
    secondaryTitle: '',
    secondaryDescription: '',
    theme: THEMES.BLUE,
    video: { id: '', url: '', title: '' }
  }
};

const SETTINGS = [
  {
    name: 'description',
    label: 'Description',
    allowedTypes: [MODULE_TYPES.DONATION]
  },
  {
    name: 'secondaryTitleAndDescription',
    label: 'Secondary Title & Description',
    allowedTypes: [MODULE_TYPES.DONATION]
  }
];

const MODULE_KEYS = {
  [MODULE_TYPES.DONATION]: 'donationModule'
};

const CONFIG_TITLE = {
  [MODULE_TYPES.DONATION]: 'Donation Configs'
};

const DonationForm = ({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess,
  onSettingsChange
}) => {
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;

  const settingsProps = Form.useWatch(['settings'], form);
  useEffect(() => {
    if (settingsProps) {
      onSettingsChange(settingsProps);
    }
  }, [settingsProps]);

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      form.setFieldsValue({
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },
        config: {
          key: defaultValues?.moduleData?.config?.key ?? '',
          theme: defaultValues?.moduleData?.config?.theme ?? THEMES.BLUE,
          secondaryTitle:
            defaultValues?.moduleData?.config?.secondaryTitle ?? '',
          secondaryDescription:
            defaultValues?.moduleData?.config?.secondaryDescription ?? '',
          video: {
            id: defaultValues?.moduleData?.config?.video?.id ?? '',
            title: defaultValues?.moduleData?.config?.video?.title ?? '',
            url:
              defaultValues?.moduleData?.config?.video?.videoThumbnail?.url ??
              ''
          }
        }
      });
    }
  }, [form, moduleId, formType, defaultValues, form]);

  const handleSubmit = ({ config, settings, permissions, ...rest }) => {
    const key = MODULE_KEYS[type];
    if (!key) return;

    const { video, key: keyField, ...restConfig } = config ?? {};

    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,
        config: {
          ...restConfig,
          ...(!isEdit && { key: keyField }),
          videoId: video?.id ?? ''
        }
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <ModuleFields />
      <Space className="w-full" direction="vertical">
        <ShowFields settings={SETTINGS} type={type} />
        <Space className="w-full" direction="vertical">
          <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
          <div>
            <Form.Item label="Key" name={['config', 'key']}>
              <KeyInput
                disabled={isEdit}
                readOnly={isEdit}
                placeholder="Enter key"
              />
            </Form.Item>

            <Form.Item
              label="Secondary Title"
              name={['config', 'secondaryTitle']}
            >
              <Input placeholder="Enter secondary title" />
            </Form.Item>
            <Form.Item
              label="Secondary Description"
              name={['config', 'secondaryDescription']}
            >
              <Input placeholder="Enter secondary description" />
            </Form.Item>
            {/* currently not needed  */}
            {/* <Form.Item label="Theme" name={['config', 'theme']}>
              <ThemePicker themes={DONATION_THEMES} />
            </Form.Item> */}
            <Form.Item label="Select Video" name={['config', 'video']}>
              <SelectVideos multiple={false} />
            </Form.Item>
          </div>
        </Space>
      </Space>
      <Permissions />
      <div className="d-flex button-section">
        <Space>
          <Form.Item>
            <Button
              type="text"
              htmlType="submit"
              className="text-btn mr-8"
              size="middle"
              disabled={loading}
              loading={loading}
            >
              Save
            </Button>
          </Form.Item>

          <Form.Item>
            <Button
              disabled={loading}
              onClick={onCancel}
              type="text"
              className="text-btn2"
            >
              Cancel
            </Button>
          </Form.Item>
        </Space>
      </div>
    </Form>
  );
};

export default DonationForm;
